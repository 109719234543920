import React from 'react'
import PropTypes from 'prop-types'

import { Layout, Carousel } from '../components/common'
import { MetaData } from '../components/common/meta'

import AboutImage from '../components/images/about'
import Evan from '../components/images/people/evan'
import Nicole from '../components/images/people/nicole'
import Jorge from '../components/images/people/jorge'
import Billy from '../components/images/people/billy'
import Hannah from '../components/images/people/hannah'

import Amy from '../components/images/people/experts/amy'
import Arielle from '../components/images/people/experts/arielle'
import Erica from '../components/images/people/experts/erica'
import Justin from '../components/images/people/experts/justin'
import Michelle from '../components/images/people/experts/michelle'
import Robin from '../components/images/people/experts/robin'

const team = [
    {
        "name": "Evan Golub",
        "title": "Co-Founder + CEO",
        "photo": <Evan/>,
        "description": "After being misdiagnosed for 4 years across 30 practitioners, he finally received his answer: Lyme disease. He has since left the trading desk to dedicate his life to getting smart on chronic conditions and helping others do the same."
    },
    {
        "name": "Nicole Krinick",
        "title": "Co-Founder + Chief Brand Officer",
        "photo": <Nicole/>,
        "description": "Early in her health journey, Nicole decided that no one facing chronic illness should ever have to feel alone again. Since then, she's dedicated herself to that mission. She believes at her core that a centralized place for support, guidance, and information can have the greatest impact on the chronic illness community."
    },
    {
        "name": "Jorge Perez",
        "title": "Chief Technology Officer",
        "photo": <Jorge/>,
        "description": "He has over 10 years of experience building iOS, Android, web, and TV applications, having spent the last 6 years at healthcare startups. He is passionate about using data to help users find answers to their health questions.",
    },
    {
        "name": "Billy Abajian",
        "title": "Director, Strategy + People",
        "photo": <Billy/>,
        "description": "A consultant by trade, he has worked in both small and large healthcare systems. Billy thrives at increasing productivity and brand awareness at any organization. He is passionate about patient outcomes, and integrative medicine."
    },
    {
        "name": "Hannah Wright",
        "title": "Community Manager",
        "photo": <Hannah/>,
        "description": "She has built and managed several pipelines across healthcare non-profits. Her first-hand understanding of chronic illness has allowed her to cultivate online and offline communities for those in need of support."
    }
]

const experts = [
    {
        "name": "Michelle L. Shuffett, MD",
        "title": "VP, Scientific Research",
        "title2": "Columbia Care Everyday Health",
        "photo": <Michelle/>,
    },
    {
        "name": "Amy O’Connor",
        "title": "Editor in Chief",
        "title2": "HealthiNation Everyday Health",
        "photo": <Amy/>,
    },
    {
        "name": "Robin Foroutan, MS, RDN",
        "title": "National Spokesperson",
        "title2": "Academy Of Nutrition and Dietetics",
        "photo": <Robin/>,
    },
    {
        "name": "Arielle Hayat",
        "title": "Senior Herbalist",
        "title2": "Alchemist Kitchen",
        "photo": <Arielle/>,
    },
    {
        "name": "Erica Matluck, ND FNP",
        "title": "Integrative PCP",
        "title2": "One Medical Group",
        "photo": <Erica/>,
    },
    {
        "name": "Justin Laube, MD",
        "title": "Assistant Clinical Professor",
        "title2": "UCLA Center for East-West Medicine",
        "photo": <Justin/>,
    }
]

const About = ({ location, pageContext }) => {
    return (
        <>
            <MetaData location={location} title={pageContext.title} description={pageContext.description}/>
            <Layout pageContext={pageContext}>
                <div className="container" style={{padding: "20px"}}>
                    <div className="row">
                        <div className="col-12 col-sm-8">
                            <h1>The Wana Story</h1>
                            <p>Wana was built by a team of people who live with chronic and invisible conditions and understand what our community members are going through. Our founders met by chance and connected over their shared health stories: mysterious symptoms, frustrating misdiagnoses, and a sense of isolation. As they bonded over their symptoms, shared advice about treatments, and supported each other in finding answers, their health challenges became a lot less overwhelming. They got better, together.</p>
                        </div>
                        <div className="col-12 col-sm-4  d-none d-sm-block">
                            <img src="/images/icons/mission_2.svg" alt="Reader" style={{padding: "0px"}}/>
                        </div>
                        <div className="col-12 col-sm-4 d-sm-none d-block">
                          <img src="/images/icons/mission_2.svg" alt="Reader" style={{padding: "0px 50px"}}/>
                        </div>
                    </div>
                    <div className="row" style={{marginTop: "40px"}}>
                        <div className="col-12 col-sm-4 d-none d-sm-block">
                          <img src="/images/icons/mission_1.svg" alt="Reader" style={{padding: "0px"}}/>
                        </div>
                        <div className="col-12 col-sm-8">
                            <h2>Our Mission</h2>
                            <p>Wana is a community that fosters hope, direction, and healing for people with chronic and invisible health conditions. The community enables users to connect, learn and discover while empowering them to feel better, together.</p>
                        </div>
                        <div className="col-12 col-sm-4 d-sm-none d-block">
                          <img src="/images/icons/mission_1.svg" alt="Reader" style={{padding: "0px 50px"}}/>
                        </div>
                    </div>
                    <div className="row" style={{marginTop: "40px", marginBottom: "40px"}}>
                        <div className="col">
                            <h2>Meet the Team</h2>
                        </div>
                    </div>
                    <div className="row">
                        {team.map((member, i) => (
                            <div key={i} className="col-12 col-sm-6">
                                <div className="row">
                                    <div className="col-4">
                                        {member["photo"]}
                                    </div>
                                    <div className="col-8">
                                        <h2>{member["name"]}</h2>
                                        <h3>{member["title"]}</h3>
                                        <p>{member["description"]}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="row" style={{margin: "40px 0px"}}>
                        <div className="col">
                            <h2>Meet the Experts</h2>
                        </div>
                    </div>
                    <div className="row">
                        {experts.map((expert, i) => (
                            <div key={i} className="col-12 col-md-6">
                                <div className="row">
                                    <div className="col-3">
                                        {expert["photo"]}
                                    </div>
                                    <div className="col-9">
                                        <h2>{expert["name"]}</h2>
                                        <p style={{margin: "0px"}}>{expert["title"]}</p>
                                        <p>{expert["title2"]}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="row" style={{marginTop: "40px", marginBottom: "40px"}}>
                        <div className="col">
                            <h2 style={{textAlign: "center"}}>Wana in the press</h2>
                            <Carousel/>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}

About.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
}

export default About
